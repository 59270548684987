import React from 'react';
import { isMobile } from 'react-device-detect';
import { Col, Result, Row, Typography, Divider, Layout, DatePicker, Button, Alert, Radio } from 'antd';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { validate } from 'validate.js';
import { genderOptions, referensiPPDBOptions } from '../../data/options';
import { CheckCircleOutlined, LoginOutlined, MessageOutlined } from '@ant-design/icons';
import { _setAxios, _success, _validationFormMsg } from '../../lib/Helper';
import config from '../../Config';
import "./style.css"
import { FormControl, FormHelperText } from '@mui/material';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(config.secret_key);
const { Title, Text } = Typography;
const { Footer } = Layout;

export default class Registrasi extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isRegistered: false,
            registered: null,
            values: {},
            errors: {},
            loadingBtn: false,
            isActivePPDB: false,
            ddl: {
                jenisJalurPPDB: []
            }
        }
    }

    componentDidMount() {
        const encryptedRegistered = localStorage.getItem('registered');
        if (encryptedRegistered !== null && encryptedRegistered !== "") {
            const values = cryptr.decrypt(encryptedRegistered);

            if (values) {
                this.setState({
                    isRegistered: true,
                    registered: {
                        ...JSON.parse(values)
                    }
                })
            }
        }

        this.getJenisJalurPPDB()
        this.getPPDB()
    }

    getJenisJalurPPDB = () => {
        _setAxios("ppdb/jalur/dropdown", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        jenisJalurPPDB: resp.data.data
                    }
                });
            }
        })
    }
    getPPDB = () => {
        _setAxios("ppdb/isactive-app", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    isActivePPDB: resp.data.data && resp.data.data.attr1_val === "1" ? true : false
                });
            }
        })
    }


    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    onSubmit = async () => {
        const { values } = this.state;
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loadingBtn: true
        })

        let param = {
            ppdb_jalur_id: values.jenis_jalur_PPDB ? values.jenis_jalur_PPDB.id : null,
            nisn: values.nisn ? values.nisn : null,
            nama: values.nama ? values.nama : null,
            jenis_kelamin: values.jenis_kelamin ? values.jenis_kelamin : null,
            tempat_lahir: values.tempat_lahir ? values.tempat_lahir : null,
            tgl_lahir: values.tgl_lahir ? values.tgl_lahir : null,
            alamat: values.alamat ? values.alamat : null,
            no_telp: values.no_telp ? values.no_telp : null,
            email: values.email ? values.email : null,
            sekolah_asal: values.sekolah_asal ? values.sekolah_asal : null,
            referensi_ppdb: values.referensi_ppdb ? values.referensi_ppdb : null,
        }

        _setAxios("ppdb/siswa", "POST", param).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                localStorage.setItem('registered', cryptr.encrypt(JSON.stringify(resp.data.data)));
                setTimeout(() => {
                    window.location.reload(false);
                }, 100);
            } else {
                this.setState({
                    loadingBtn: false
                })
            }
        })
    }

    render() {

        const { values, errors, loadingBtn, ddl, isActivePPDB } = this.state

        if (!isActivePPDB) {
            return <Row gutter={[16, 16]}>
                <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                    <div className='form-box form-box-header'>
                        <Title level={3}>PPDB MAN 1 GRESIK</Title>
                        <Text type="secondary">Registrasi Penerimaan Peserta Didik Baru {new Date().getFullYear()}/{new Date().getFullYear() + 1}</Text>
                        <Divider />
                        <Alert
                            message="Informasi"
                            description={<>Saat ini halaman PPDB MAN 1 Gresik masih ditutup/dinonaktifkan oleh admin, mohon memeriksa website resmi <a href={config.web_man.url} target='_blank' rel="noopener noreferrer" style={{ fontWeight: 'bold' }}>{config.web_man.name}</a> untuk mendapat informasi selanjutnya</>}
                            type="info"
                            showIcon
                        />
                    </div>
                </Col>
            </Row>
        }

        return <div style={{ background: "#f0ebf8" }}>
            <Row gutter={[16, 16]}>
                <Col span={isMobile ? 24 : 12} offset={isMobile ? 0 : 6}>
                    <div className='form-box form-box-header'>
                        <Title level={3}>PPDB MAN 1 GRESIK</Title>
                        <Text type="secondary">Registrasi Penerimaan Peserta Didik Baru {new Date().getFullYear()}/{new Date().getFullYear() + 1}</Text>
                        <Divider />

                        <Alert
                            message="Informasi"
                            description={<>Setelah registrasi berhasil, informasi akun akan dikirimkan ke No. whatsapp kamu. <br />Pastikan semua data sudah benar dan No.Whatsapp kamu aktif ya..<br /><br />Setelah itu kamu bisa melengkapi data diri kamu diakun tersebut...</>}
                            type="info"
                            showIcon
                        />
                    </div>
                    {
                        this.state.isRegistered ?
                            <div className='form-box'> <Result
                                status="success"
                                title="Selamat, Pendaftaran Berhasil Dibuat."
                                subTitle="Segera cek akun kamu untuk melengkapi data dan berkas pendukung. Apabila ada kesulitan, silakan menghubungi admin MAN 1 Gresik."
                                extra={[
                                    <Button type="primary" key="login" icon={<LoginOutlined />} onClick={() => {
                                        window.location.href = config.base_url + "/login"
                                    }}>
                                        Login
                                    </Button>,
                                    <Button key="chat" icon={<MessageOutlined />} onClick={() => {
                                        window.open('https://api.whatsapp.com/send/?phone=%2B' + config.phone_admin + '&text=Assalamualaikum...&type=phone_number&app_absent=0');
                                    }}>Whatsapp Admin</Button>,
                                ]}
                            />
                                <div style={{ textAlign: "right" }}>Kamu ingin mendaftarkan diri lagi? <span style={{ color: "#1890ff", cursor: "pointer" }} onClick={() => {
                                    localStorage.removeItem("registered");
                                    window.location.reload(false);
                                }}>Klik disini</span></div>
                            </div> :
                            <>
                                <div className='form-box'>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24}>
                                            <Text>Jenis Jalur PPDB <span className='required'>*</span></Text>
                                            <Autocomplete
                                                options={ddl.jenisJalurPPDB}
                                                value={values.jenis_jalur_PPDB || null}
                                                getOptionLabel={(option) =>
                                                    option.kategori + " - " + option.nama + (option.is_open === "0" ? " (Tidak aktif)" : "")
                                                }
                                                getOptionDisabled={(option) => option.is_open === "0"}
                                                onChange={(e, newValue) => {
                                                    this.handleChangeSetValue("jenis_jalur_PPDB", newValue)
                                                }}
                                                renderInput={(params) => <TextField {...params}
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    name="jenis_jalur_PPDB"
                                                    placeholder='Jenis jalur PPDB'
                                                    type="text"
                                                    error={this.hasError('jenis_jalur_PPDB')}
                                                    helperText={
                                                        this.hasError('jenis_jalur_PPDB') ? errors.jenis_jalur_PPDB[0] : null
                                                    }
                                                />}
                                            />
                                        </Col>
                                        <Col xs={24}>
                                            <Text>NISN (Nomor Induk Siswa Nasional) <span className='required'>*</span></Text>
                                            <TextField
                                                className='form-box-input'
                                                fullWidth
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="nisn"
                                                placeholder='Nisn'
                                                type="text"
                                                onChange={(e) => {
                                                    this.handleChangeSetValue("nisn", e.target.value)
                                                }}
                                                value={values.nisn || ''}
                                                error={this.hasError('nisn')}
                                                helperText={
                                                    this.hasError('nisn') ? errors.nisn[0] : null
                                                }
                                            />
                                        </Col>
                                        <Col xs={24}>
                                            <Text>Nama Lengkap <span className='required'>*</span></Text>
                                            <TextField
                                                className='form-box-input'
                                                fullWidth
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="nama"
                                                placeholder='Nama lengkap'
                                                type="text"
                                                onChange={(e) => {
                                                    this.handleChangeSetValue("nama", e.target.value)
                                                }}
                                                value={values.nama || ''}
                                                error={this.hasError('nama')}
                                                helperText={
                                                    this.hasError('nama') ? errors.nama[0] : null
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className='form-box'>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24}>
                                            <Text>Jenis Kelamin <span className='required'>*</span></Text>
                                            <div style={{ marginTop: 16 }}>
                                                <Radio.Group
                                                    name="jenis_kelamin"
                                                    defaultValue={values.jenis_kelamin}
                                                    onChange={(e) => {
                                                        this.handleChangeSetValue("jenis_kelamin", e.target.value)
                                                    }}
                                                >
                                                    {genderOptions.map((row, i) => (
                                                        <Radio key={i} value={row.value}>{row.label}</Radio>
                                                    ))}
                                                </Radio.Group>
                                            </div>
                                            <span className='input-error'>{errors.jenis_kelamin && errors.jenis_kelamin.length > 0 ? errors.jenis_kelamin[0] : ""}</span>
                                        </Col>
                                        <Col xs={24}>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={14}>
                                                    <Text>Tempat Lahir <span className='required'>*</span></Text>
                                                    <TextField
                                                        className='form-box-input'
                                                        fullWidth
                                                        size="small"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        placeholder="Tempat lahir"
                                                        name="tempat_lahir"
                                                        type="text"
                                                        onChange={(e) => {
                                                            this.handleChangeSetValue("tempat_lahir", e.target.value)
                                                        }}
                                                        value={values.tempat_lahir || ''}
                                                        error={this.hasError('tempat_lahir')}
                                                        helperText={
                                                            this.hasError('tempat_lahir') ? errors.tempat_lahir[0] : null
                                                        }
                                                    />
                                                </Col>
                                                <Col xs={10}>
                                                    <Text>Tanggal Lahir <span className='required'>*</span></Text>
                                                    <DatePicker
                                                        style={{ marginTop: 8 }}
                                                        placeholder="Tanggal lahir"
                                                        onChange={(e, val) => {
                                                            this.handleChangeSetValue("tgl_lahir", val)
                                                        }} />
                                                    <span className='input-error'>{errors.tgl_lahir && errors.tgl_lahir.length > 0 ? errors.tgl_lahir[0] : ""}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24}>
                                            <Text>Alamat <span className='required'>*</span></Text>
                                            <TextField
                                                className='form-box-input'
                                                size="small"
                                                fullWidth
                                                multiline
                                                minRows={2}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="alamat"
                                                placeholder='Alamat'
                                                type="text"
                                                onChange={(e) => {
                                                    this.handleChangeSetValue("alamat", e.target.value)
                                                }}
                                                value={values.alamat || ''}
                                                error={this.hasError('alamat')}
                                                helperText={
                                                    this.hasError('alamat') ? errors.alamat[0] : null
                                                }
                                            />
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Text>No. Whatsapp <span className='required'>*</span></Text>
                                            <FormHelperText>Nomor WhatsApp yang Anda masukkan akan digunakan untuk pengiriman informasi akun. Pastikan nomor yang dimasukkan aktif dan bisa menerima pesan.</FormHelperText>
                                            <FormControl component="fieldset" style={{ width: '100%' }}>
                                                <TextField
                                                    className='form-box-input'
                                                    size="small"
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    name="no_telp"
                                                    placeholder='081xxxx'
                                                    type="text"
                                                    onChange={(e) => {
                                                        this.handleChangeSetValue("no_telp", e.target.value)
                                                    }}
                                                    value={values.no_telp || ''}
                                                    error={this.hasError('no_telp')}
                                                    helperText={
                                                        this.hasError('no_telp') ? errors.no_telp[0] : null
                                                    }
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Text>E-Mail <span className='required'>*</span></Text>
                                            <FormHelperText>Alamat E-Mail yang Anda daftarkan akan digunakan untuk login dan pemulihan akun. Pastikan email yang dimasukkan benar serta dapat diakses kapan saja.</FormHelperText>
                                            <TextField
                                                className='form-box-input'
                                                size="small"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                placeholder="example@gmail.com"
                                                name="email"
                                                type="text"
                                                onChange={(e) => {
                                                    this.handleChangeSetValue("email", e.target.value)
                                                }}
                                                value={values.email || ''}
                                                error={this.hasError('email')}
                                                helperText={
                                                    this.hasError('email') ? errors.email[0] : null
                                                }
                                            />
                                        </Col>
                                        <Col xs={24}>
                                            <Text>Asal Sekolah <span className='required'>*</span></Text>
                                            <TextField
                                                className='form-box-input'
                                                size="small"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                name="sekolah_asal"
                                                placeholder='Asal sekolah'
                                                type="text"
                                                onChange={(e) => {
                                                    this.handleChangeSetValue("sekolah_asal", e.target.value)
                                                }}
                                                value={values.sekolah_asal || ''}
                                                error={this.hasError('sekolah_asal')}
                                                helperText={
                                                    this.hasError('sekolah_asal') ? errors.sekolah_asal[0] : null
                                                }
                                            />
                                        </Col>
                                        <Col xs={24}>
                                            <Text>Referensi PPDB <span className='required'>*</span></Text>
                                            <Autocomplete
                                                className='form-box-input'
                                                options={referensiPPDBOptions}
                                                value={values.referensi_ppdb || ''}
                                                onChange={(e, newValue) => {
                                                    this.handleChangeSetValue("referensi_ppdb", newValue ? newValue.value : null)
                                                }}
                                                renderInput={(params) => <TextField {...params}
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    name="referensi_ppdb"
                                                    placeholder='Referensi PPDB'
                                                    type="text"
                                                    error={this.hasError('referensi_ppdb')}
                                                    helperText={
                                                        this.hasError('referensi_ppdb') ? errors.referensi_ppdb[0] : null
                                                    }
                                                />}
                                            />
                                        </Col>
                                        <Col xs={24}>
                                            <Button icon={<CheckCircleOutlined />} type='primary' className='btn-form-registrasi' onClick={this.onSubmit} loading={loadingBtn}>Daftar</Button>
                                            <Button onClick={() => { window.history.back() }} className='btn-form-registrasi' loading={loadingBtn} style={{ marginRight: 8 }}>Batal</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                    }
                </Col>
                <Col xs={24}>
                    <Footer style={{ textAlign: 'center', marginBottom: isMobile ? 40 : 0 }}>Madrasah Digital MAN 1 Gresik @{new Date().getFullYear()}</Footer>
                </Col>
            </Row>
        </div>
    }
}

const schema = {
    jenis_jalur_PPDB: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    nisn: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Nisn harus berupa agka");
            }
        },
        length: {
            minimum: 10,
            maximum: 10,
            message: "jumlah karakter harus 10 digit"
        },
    },
    nama: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        length: {
            maximum: 200
        },
        format: {
            pattern: /^$|[a-zA-Z'-\s]+/,
            message: function () {
                return validate.format("^Nama lengkap harus berupa huruf");
            }
        },
    },
    jenis_kelamin: {
        presence: { allowEmpty: false, message: 'harus diisi' }
    },
    tempat_lahir: {
        presence: { allowEmpty: false, message: 'harus diisi' }
    },
    tgl_lahir: {
        presence: { allowEmpty: false, message: 'harus diisi' }
    },
    alamat: {
        presence: { allowEmpty: false, message: 'harus diisi' }
    },
    no_telp: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        length: {
            maximum: 14,
            message: "terlalu panjang (maksimal 14 karakter)"
        },
        format: {
            pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
            message: function (value) {
                return validate.format("^No telp %{phone} tidak valid", {
                    phone: value
                });
            }
        },
    },
    email: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        format: {
            pattern: /^$|\S+@\S+\.\S+/,
            message: function (value) {
                return validate.format("^Email %{email} tidak valid", {
                    email: value
                });
            }
        },
    },
    sekolah_asal: {
        presence: { allowEmpty: false, message: 'harus diisi' }
    },
    referensi_ppdb: {
        presence: { allowEmpty: false, message: 'harus dipilih' }
    },
}