import React from 'react';

import { Drawer, List } from 'antd';
import { MobileView } from 'react-device-detect';
import { TabBar } from 'antd-mobile'

import './style.css'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// const pathKesiswaan = ["/kesiswaan/tata-tertib-sekolah", "/kesiswaan/pelanggaran-siswa", "/kesiswaan/penghargaan-siswa"]

class TabBarFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pathname: window.location,
      tabbar: {
        visible: false,
        title: null,
        data: []
      }
    }
  }

  componentDidMount() {
    const { pathname } = window.location
    this.setState({
      pathname: pathname
    })

    console.log("access", this.props.menu)
  }

  setRouteActive = (path) => {
    if (path === "kesiswaan" || path === "bimbingan-konseling" || path === "presensi") {

      let title = "Bimbingan Konseling"
      let data = [
        {
          title: "Profil Bimbingan Konseling",
          path: "/bimbingan-konseling/profil-bk",
        },
        {
          title: "Hasil Tes Peserta Didik",
          path: "/bimbingan-konseling/hasil-tes",
        },
        {
          title: "Media Informasi",
          path: "/bimbingan-konseling/media-informasi",
        },
        {
          title: "Konsultasi",
          path: "/bimbingan-konseling/konsultasi",
        },
      ]
      if (path === "kesiswaan") {
        title = "Kesiswaan"
        data = [
          {
            title: "Tata Tertib Sekolah",
            path: "/kesiswaan/tata-tertib-sekolah",
          },
          {
            title: "Pelanggaran Siswa",
            path: "/kesiswaan/pelanggaran-siswa",
          },
          {
            title: "Penghargaan Siswa",
            path: "/kesiswaan/penghargaan-siswa",
          }
        ]
      } else if (path === "presensi") {
        title = "Presensi"
        data = [
          {
            title: "Kehadiran",
            path: "/presensi/kehadiran",
          },
          {
            title: "Ketidakhadiran",
            path: "/presensi/ketidakhadiran",
          }
        ]
      }
      this.setState({
        tabbar: {
          ...this.state.tabbar,
          visible: true,
          title: title,
          data: data
        },
        // pathname: path + ".bypass"
      })
    } else {
      this.props.history.push(path);
      this.setState({
        pathname: path
      })
    }
  }

  render() {
    let tabs = []

    tabs = [
      {
        key: '/',
        title: 'Dashboard',
        icon: <span className="icon-tab-bar icon-dashboard"></span>,
      },
      {
        key: '/profil-calon-peserta-didik',
        title: 'Profil Calon Peserta Didik',
        icon: <span className="icon-tab-bar icon-profile"></span>,
      },
    ];

    return (
      <>
        <MobileView>
          <TabBar
            className='tab-bar'
            activeKey={this.state.pathname}
            onChange={value => this.setRouteActive(value)}
          >
            {tabs.map(item => (<TabBar.Item key={item.key} icon={item.icon} title={item.title} />))}
          </TabBar>
        </MobileView>

        <Drawer
          title={this.state.tabbar.title}
          placement="bottom"
          className='tab-bar-drawer'
          height={260}
          onClose={() => {
            this.setState({
              tabbar: {
                ...this.state.tabbar,
                visible: false
              }
            })
          }}
          open={this.state.tabbar.visible}
        >
          <List
            bordered
            dataSource={this.state.tabbar.data}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  onClick={() => {
                    this.setState({
                      tabbar: {
                        ...this.state.tabbar,
                        visible: false
                      },
                      pathname: item.path
                    })
                    this.props.history.push(item.path);
                  }}
                  title={item.title}
                />
              </List.Item>
            )}
          />
        </Drawer>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
    menu: state.menu,
    menuCollapse: state.menuCollapse
  }
}
export default connect(mapStateToProps)(withRouter(TabBarFooter));
