import React from 'react';
import { Row, Col, Tooltip, Button, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { _setAxios } from '../../lib/Helper';
import config from '../../Config';

class ContohBerkas extends React.Component {
  onDownloadCV = () => {
    let param = {
      siswa_id: this.props.privilege.profile_ex.id
    }
    _setAxios("ppdb/siswa/export-pdf", "POST", param).then(resp => {
      if (resp.status === true) {
        window.open(resp.data.data.link)
      }
    })
  }

  render() {
    const { loading } = this.props
    return (
      loading ? "" :
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <div className='dashboard-column-1-ucapan'>Contoh Dokumen Pendukung</div>
          </Col>
          <Col xs={24}>
            <p>Contoh dokumen pendukung yang bisa diunduh/didownload calon siswa untuk melengkapi persyaratan berkas PPDB</p>
            <Space direction={"vertical"}>

              <Tooltip title="Download Surat Keterangan Jalur Afirmasi">
                <Button type="primary" icon={<DownloadOutlined />} style={{ borderRadius: 6 }} onClick={() => {
                  window.open(config.assets_url+"/ppdb/files/SURAT-KET-JALUR-AFIRMASI.docx")
                }}>
                  Surat Keterangan Jalur Afirmasi
                </Button>
              </Tooltip>
              <Tooltip title="Download Surat Rekomendasi Jalur Kemitraan">
                <Button type="primary" icon={<DownloadOutlined />} style={{ borderRadius: 6 }} onClick={() => {
                  window.open(config.assets_url+"/ppdb/files/SURAT-REKOMENDASI-JALUR-KEMITRAAN.docx")
                }}>
                  Surat Rekomendasi Jalur Kemitraan
                </Button>
              </Tooltip>
              <Tooltip title="Download Surat Rekomendasi Ketua Osis">
                <Button type="primary" icon={<DownloadOutlined />} style={{ borderRadius: 6 }} onClick={() => {
                  window.open(config.assets_url+"/ppdb/files/SURAT-REKOMENDASI-KETUA-OSIS.docx")
                }}>
                  Surat Rekomendasi Ketua Osis
                </Button>
              </Tooltip>
              <Tooltip title="Download Surat Rekomendasi Raport">
                <Button type="primary" icon={<DownloadOutlined />} style={{ borderRadius: 6 }} onClick={() => {
                  window.open(config.assets_url+"/ppdb/files/SURAT-REKOMENDASI-RAPORT.docx")
                }}>
                  Surat Rekomendasi Raport
                </Button>
              </Tooltip>

            </Space>
          </Col>
        </Row>
    )
  }
}


export default ContohBerkas